import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import "../styles/InputField.css";

interface InputFieldProps {
  adornment?: string;
  inputValue?: string;
  label: string;
  setInputValue: (value: string) => void;
  type: string;
}

const InputField = (props: InputFieldProps) => {
  return (
    <div className="inputField">
      <FormControl fullWidth>
        <InputLabel htmlFor="outlined-adornment-amount">
          {props.label}
        </InputLabel>
        <OutlinedInput
          type={props.type}
          id="outlined-adornment-amount"
          endAdornment={
            props.adornment && (
              <InputAdornment position="end">{props.adornment}</InputAdornment>
            )
          }
          value={props.inputValue}
          onChange={(e) => props.setInputValue(e.target.value)}
        />
      </FormControl>
    </div>
  );
};

export default InputField;

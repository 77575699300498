import { useState, useCallback } from "react";
import axios from "axios";

export interface ReceiptRequestBody {
  date: string;
  email: string;
  cardNumber: string;
  cardType: string;
  totalAmount: number;
}

export interface CampaignClaimBody {
  email: string;
  campaignCode: string;
}

export const usePostRequest = <GenericRequestBody>(): {
  post: (data: GenericRequestBody, url: string) => Promise<void>;
  loading: boolean;
  error: string | null;
  responseStatus: number | null;
} => {
  const [responseStatus, setResponseStatus] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const post = useCallback(async (data: GenericRequestBody, url: string) => {
    try {
      setLoading(true);
      const response = await axios.post(url, data);

      setResponseStatus(response.status);
      setLoading(false);
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  }, []);

  return { responseStatus, loading, error, post };
};

import { Link } from "@mui/material";
import "../styles/Footer.css";
import greenLogo from "../assets/svgs/AimoCharge_logo_green_RGB.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footerContainer">
      <ul className="footerLinkContainer">
        <li>
          <Link
            href="
            https://www.aimopark.se/om-aimo-park/om-webbplatsen/integritetspolicy/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="footerText">{t("Footer.privacyPolicy")}</span>
          </Link>
        </li>
        <li>
          <Link
            href="
            https://www.aimopark.se/kundservice/avtalsvillkor/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="footerText">{t("Footer.termsAndConditions")}</span>
          </Link>
        </li>
        <li>
          <Link
            href="https://www.aimopark.se/"
            target="_blank"
            rel="noreferrer"
          >
            <span className="footerText">{t("Footer.website")}</span>
          </Link>
        </li>
      </ul>
      <div className="footerLogoContainer">
        <img src={greenLogo} alt="Aimo Park" />
      </div>
    </div>
  );
};

export default Footer;

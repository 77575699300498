import "../styles/SubmitButton.css";

interface SubmitButtonProps {
  disabledConditions: boolean;
  handleSubmit: () => void;
  loading: boolean;
  buttonLabel: string;
  className?: string;
}

const SubmitButton = (props: SubmitButtonProps) => {
  return (
    <div className="buttonContainer">
      <button
        disabled={props.disabledConditions}
        onClick={props.handleSubmit}
        className={`buttonBase ${props.className}`}
      >
        {props.loading ? (
          <div className="smallLoader"></div>
        ) : (
          props.buttonLabel
        )}{" "}
      </button>
    </div>
  );
};

export default SubmitButton;

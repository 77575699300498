import "../styles/ReceiptRequest.css";
import "../styles/Loader.css";
import chargingCar from "../assets/pngs/chargingCar.png";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/sv";
import { ChangeEvent, createRef, KeyboardEvent, useState } from "react";
import { ReceiptRequestBody, usePostRequest } from "../hooks/useApi";
import { ConfirmationModal } from "./ConfirmationModal";
import PrivacyPolicy from "./PrivacyPolicy";
import InputField from "./InputField";
import SubmitButton from "./SubmitButton";
import { useTranslation } from "react-i18next";

const cardTypes = [
  "Visa",
  "Mastercard",
  "American Express",
  "Diners",
  "JCB",
  "Maestro International",
  "China Union Pay",
];

const ReceiptRequest = () => {
  const { loading, error, post } = usePostRequest<ReceiptRequestBody>();
  const [cardType, setCardType] = useState<string>();
  const [date, setDate] = useState<Dayjs>(dayjs());
  const [amount, setAmount] = useState<string | undefined>();
  const [policyAgreed, setPolicyAgreed] = useState<boolean>(false);
  const [cardNumber, setCardNumber] = useState<Array<number | undefined>>([
    undefined,
    undefined,
    undefined,
    undefined,
  ]);
  const [email, setEmail] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { t } = useTranslation();

  const refArray = Array.from({ length: 4 }, () =>
    createRef<HTMLInputElement>()
  );

  const handleKeyUp = (
    event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (event.key === "Backspace") {
      const prevIndex = refArray[index - 1 > 0 ? index - 1 : 0].current;
      prevIndex?.focus();
    }
  };

  const handleChangeCardNumber = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    if (event.target.value.length > 0) {
      if (index === 3) {
        const current = refArray[3].current;
        current?.blur();
      } else {
        const nextIndex = refArray[index + 1].current;
        nextIndex?.focus();
      }
    }
    setCardNumber(
      cardNumber.map((item, i) =>
        i === index
          ? parseInt(
              event.target.value.substring(event.target.value.length - 1)
            )
          : item
      )
    );
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmitRequest = async () => {
    if (cardType && cardNumber && email && amount) {
      const cardNumberString = cardNumber.reduce(
        (a, b) => a?.toString() + b?.toString(),
        ""
      );
      await post(
        {
          date: date.toISOString().split("T")[0],
          cardNumber: cardNumberString,
          email,
          cardType,
          totalAmount: parseFloat(amount),
        },
        "/api/receiptRequest"
      );
      if (!loading) {
        setOpenModal(true);
      }
    }
  };

  return (
    <div className="requestContainer">
      <div className="titleContainer">
        <img src={chargingCar} alt="" />
        <h1 className="title">{t("ReceiptRequest.title")}</h1>
      </div>
      <div className="inputContainer">
        <p>{t("ReceiptRequest.instructions")}</p>
        <h3>{t("ReceiptRequest.address")}</h3>
        <div className="inputField">
          <FormControl fullWidth>
            <LocalizationProvider
              adapterLocale={"sv"}
              dateAdapter={AdapterDayjs}
            >
              <DatePicker
                className="datePicker"
                onChange={(date) => setDate(date ? date : dayjs())}
                disableFuture
                value={date}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </div>
        <InputField
          type="number"
          inputValue={amount}
          setInputValue={setAmount}
          label={t("ReceiptRequest.amountField")}
          adornment="SEK"
        />
        <InputField
          type="email"
          inputValue={email}
          setInputValue={setEmail}
          label={t("ReceiptRequest.emailField")}
          adornment="@"
        />
        <div className="inputField">
          <FormControl fullWidth>
            <InputLabel id="chooseCardType">
              {t("ReceiptRequest.chooseCardField")}
            </InputLabel>
            <Select
              value={cardType}
              labelId="chooseCardType"
              label={t("ReceiptRequest.chooseCardField")}
              onChange={(e) => setCardType(e.target.value.toLowerCase())}
              className="select"
            >
              {cardTypes.map((t, i) => {
                return (
                  <MenuItem key={i} value={t}>
                    {t}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div className="cardNumberInputContainer">
          <p className="inputLabel">{t("ReceiptRequest.lastDigitsField")}</p>
          <p className="phonePaymentInfo">
            {t("ReceiptRequest.lastDigitsInstructions1")}{" "}
            <b>{t("ReceiptRequest.lastDigitsInstructions2")} </b>
            {t("ReceiptRequest.lastDigitsInstructions3")}{" "}
            <a
              rel="noreferrer"
              href="https://support.apple.com/en-us/HT212269"
              target="_blank"
            >
              {t("ReceiptRequest.applePay")}
            </a>{" "}
            {t("ReceiptRequest.lastDigitsInstructions4")}{" "}
            <a
              rel="noreferrer"
              href="https://support.google.com/googlepay/answer/7643925?hl=en#zippy=%2Cvirtual-account-numbers"
              target="_blank"
            >
              {t("ReceiptRequest.googlePay")}
            </a>
          </p>
          <div className="numberInputField">
            {refArray.map((ref, i) => {
              return (
                <OutlinedInput
                  className="cardNumberDigit"
                  type="number"
                  inputRef={ref}
                  value={cardNumber[i]}
                  onKeyUp={(e) => handleKeyUp(e, i)}
                  onChange={(e) => handleChangeCardNumber(e, i)}
                  inputProps={{
                    style: { textAlign: "center" },
                    min: 0,
                    max: 9,
                  }}
                />
              );
            })}
          </div>
        </div>
        <PrivacyPolicy
          policyAgreed={policyAgreed}
          setPolicyAgreed={setPolicyAgreed}
        />
        <SubmitButton
          loading={loading}
          disabledConditions={!policyAgreed}
          handleSubmit={handleSubmitRequest}
          buttonLabel={t("ReceiptRequest.sendRequest")}
        />
        {openModal && (
          <ConfirmationModal
            openModal={openModal}
            error={error}
            errorMessage={t("ReceiptRequest.errorMessage")}
            successMessage={t("ReceiptRequest.successMessage")}
            handleCloseModal={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
};

export default ReceiptRequest;

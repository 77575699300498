import { useTranslation } from "react-i18next";
import SubmitButton from "./SubmitButton";
import globe from "../assets/svgs/APGlobe.svg";
import "../styles/LanguageSwitch.css";

const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  const handleSwitchLanguage = () => {
    const currentLanguage = i18n.resolvedLanguage;
    const newLanguage = currentLanguage === "en" ? "sv" : "en";
    i18n.changeLanguage(newLanguage);
  };
  return (
    <div className="languageSwitchContainer">
      <img src={globe} alt="" className="globeImg" />
      <SubmitButton
        disabledConditions={false}
        handleSubmit={handleSwitchLanguage}
        loading={false}
        buttonLabel={i18n.resolvedLanguage === "en" ? "Svenska" : "English"}
        className="buttonTransparent"
      />
    </div>
  );
};

export default LanguageSwitch;

import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import LanguageSwitch from "./LanguageSwitch";

function Layout() {
  return (
    <div className="App">
      <div className="flexWrapper">
        <LanguageSwitch />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}

export default Layout;

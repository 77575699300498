import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import "../styles/PrivacyPolicy.css";
import { useTranslation } from "react-i18next";

interface PrivacyPolicyProps {
  policyAgreed: boolean;
  setPolicyAgreed: (agreed: boolean) => void;
}

const PrivacyPolicy = (props: PrivacyPolicyProps) => {
  const { t } = useTranslation();

  return (
    <div className="policyContainer">
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.policyAgreed}
              onChange={() => props.setPolicyAgreed(!props.policyAgreed)}
            />
          }
          label={
            <a
              href="https://www.aimopark.se/om-aimo-park/om-webbplatsen/integritetspolicy/"
              target="_blank"
              rel="noreferrer"
              className="termsLink"
            >
              {t("PrivacyPolicy.accept")}
            </a>
          }
        ></FormControlLabel>
      </FormControl>
    </div>
  );
};
export default PrivacyPolicy;

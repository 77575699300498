import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import "../styles/ConfirmationModal.css";

interface ConfirmationModalProps {
  openModal: boolean;
  handleCloseModal: (event: React.MouseEvent<HTMLElement>) => void;
  error: string | null;
  errorMessage: string;
  successMessage: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = (
  props: ConfirmationModalProps
) => {
  const { t } = useTranslation();
  return (
    <Dialog
      className="confirmationDialog"
      open={props.openModal}
      onClose={props.handleCloseModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.error ? t("Confirmation.error") : t("Confirmation.thankyou")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>
            {" "}
            {props.error ? props.errorMessage : props.successMessage}
          </Typography>
          <Typography className="bold">
            {!props.error ? t("ReceiptRequest.successInstructions") : ""}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <button
          className={`centered ${props.error ? "buttonBlue" : "buttonBase"}`}
          onClick={props.handleCloseModal}
          autoFocus
        >
          OK
        </button>
      </DialogActions>
    </Dialog>
  );
};

import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import ClaimCampaign from "./components/ClaimCampaign";
import Layout from "./components/Layout";
import ReceiptRequest from "./components/ReceiptRequest";
import { Suspense } from "react";

function App() {
  return (
    <Suspense fallback="">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<ReceiptRequest />} />
          </Route>
          <Route path="/claim" element={<Layout />}>
            <Route index element={<ClaimCampaign />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;

import { useState } from "react";
import "../styles/ClaimCampaign.css";
import InputField from "./InputField";
import PrivacyPolicy from "./PrivacyPolicy";
import peopleFirst from "../assets/pngs/peopleFirst.png";
import SubmitButton from "./SubmitButton";
import { CampaignClaimBody, usePostRequest } from "../hooks/useApi";
import { ConfirmationModal } from "./ConfirmationModal";
import { useTranslation } from "react-i18next";

const ClaimCampaign = () => {
  const { loading, error, post } = usePostRequest<CampaignClaimBody>();
  const [email, setEmail] = useState("");
  const [campaignCode, setCampaignCode] = useState("");
  const [policyAgreed, setPolicyAgreed] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();

  const handleSubmitRequest = async () => {
    if (email && campaignCode) {
      await post({ email, campaignCode }, "/api/campaignClaim");
    }
    if (!loading) {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="requestContainer">
      <div className="titleContainer">
        <h1 className="title">{t("ClaimCampaign.title")}</h1>
      </div>
      <img className="peopleFirstImg" src={peopleFirst} alt="" />
      <div className="inputContainer">
        <p>{t("ClaimCampaign.instructions")}</p>
        <InputField
          type="text"
          inputValue={campaignCode}
          setInputValue={setCampaignCode}
          label={t("ClaimCampaign.campaignCodeField")}
        />
        <InputField
          inputValue={email}
          type="email"
          setInputValue={setEmail}
          label={t("ClaimCampaign.emailField")}
          adornment="@"
        />
        <PrivacyPolicy
          policyAgreed={policyAgreed}
          setPolicyAgreed={setPolicyAgreed}
        />
        <SubmitButton
          loading={loading}
          disabledConditions={!policyAgreed}
          handleSubmit={handleSubmitRequest}
          buttonLabel={t("ClaimCampaign.sendRequest")}
        />
      </div>
      {openModal && (
        <ConfirmationModal
          openModal={openModal}
          error={error}
          errorMessage={t("ClaimCampaign.errorMessage")}
          successMessage={t("ClaimCampaign.successMessage")}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
};

export default ClaimCampaign;
